import { Component, OnInit, ViewChild, ModuleWithComponentFactories, HostListener, NgZone, Output, EventEmitter, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatBottomSheet, MatBottomSheetRef, MatTabChangeEvent, MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { catchError, map, tap, startWith, switchMap, debounceTime, distinctUntilChanged, takeWhile, first, retry } from 'rxjs/operators';
import { MapService } from 'src/app/service/map.service';
import { CortacorrientelogService } from 'src/app/service/cortacorrientelog.service';
import { CortacorrienteService } from 'src/app/service/cortacorriente.service';
import { GpsMap } from 'src/app/model/gps-map';
import { GpsListContentComponent } from 'src/app/component/gps-list-content/gps-list-content.component';
import { MapComponent } from 'src/app/component/map/map.component';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import swal from 'sweetalert2';
import { Contrato } from '../../model/contrato';
import { Cortacorriente } from '../../model/cortacorriente';
import { Cortacorrientepass } from '../../model/cortacorrientepass';
import { Cortacorrientelog } from '../../model/cortacorrientelog';
import { ImeiService } from '../../service/imei.service';
import { LimiteVelocidadRequest } from '../../model/limite-velocidad-request';
import { GeozonaService } from 'src/app/service/geozona.service';
import { GeoConfiguracion, Geozona, TipoEvento } from 'src/app/model/geozona';
import { FormGeozonaComponent } from '../form-geozona/form-geozona.component';
import { FormDeleteGeozonaComponent } from '../form-geozona/form-delete-geozona/form-delete-geozona.component';
import { Alias } from 'src/app/model/alias';
import { AliasService } from 'src/app/service/alias.service';
import { Conductor } from 'src/app/model/conductor';
import { ConductorService } from 'src/app/service/conductor.service';
import { HistorialAsignacionConductor } from 'src/app/model/historial-asignacion-conductor';
import { HistorialAsignacionConductorService } from 'src/app/service/historial-asignacion-conductor.service';
import { User } from 'src/app/model/user';
import { PantallaReporteConductorVehiculoComponent } from '../report/pantalla-reporte-conductor-vehiculo/pantalla-reporte-conductor-vehiculo.component';
import { stringify } from '../../../../node_modules/wkt'
import { Configuracion } from 'src/app/model/configuracion';

@Component({
  selector: 'app-main-home-content',
  templateUrl: './main-home-content.component.html',
  styleUrls: ['./main-home-content.component.css']
})
export class MainHomeContentComponent implements OnInit {

  public gpsList: Array<GpsMap>;
  public gpsListActivate: Array<GpsMap>;
  public gpsListDesactivateMedio: Array<GpsMap>;
  public gpsListDesactivateFull: Array<GpsMap>;
  public gpsListView: Array<GpsMap>;
  public filterCar: String;

  public total: number;
  public totalActivate: number;
  public totalDesactivateMedio: number;
  public totalDesactivateFull: number;

  public isLoadGps: boolean = true;
  public isRefresh: boolean = false;

  public onRefresh: boolean = true;

  public serialsearchText: String;
  public searchText: String;
  searchControl = new FormControl();
  options: GpsMap[] = [] as GpsMap[];
  filteredOptions: Observable<GpsMap[]>;

  geozonasList: Geozona[] = [];
  configuracionGeozona: GeoConfiguracion;
  tipoEventoGeozona: TipoEvento;
  isLoading = false;


  public contratosCortaCorriente: Map<string, Contrato> = new Map()

  public contratosOdometro: Map<string, Contrato> = new Map() 

  @ViewChild('listGpsView') tabla: GpsListContentComponent;
  @ViewChild('map') map: MapComponent;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver,
    public mapService: MapService,
    public cortacorrienteService: CortacorrienteService,
    public cortacorrienteLogService: CortacorrientelogService,
    private bottomSheet: MatBottomSheet,
    zone: NgZone,
    private router: Router,
    private _geozonaService: GeozonaService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {

    this.gpsList = [] as Array<GpsMap>;
    this.gpsListActivate = [] as Array<GpsMap>;
    this.gpsListDesactivateMedio = [] as Array<GpsMap>;
    this.gpsListDesactivateFull = [] as Array<GpsMap>;
    this.gpsListView = [] as Array<GpsMap>;
    this.contratosCortaCorriente = new Map()

    this.contratosOdometro = new Map()
  }


  ngOnInit() {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.searchText = "";
    this.serialsearchText = "";

    this.isLoadGps = true;
    this.filterCar = '';
    this.total = 0;
    this.totalActivate = 0;
    this.totalDesactivateMedio = 0;
    this.totalDesactivateFull = 0;

    this.loadAll();

    this.filteredOptions = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map(val => {
          return this.filterAutoCompletado(val || '')
        })
      );

    this.getGeozonas();

  }

  getGeozonas() {
    this.isLoading = true;
    this._geozonaService.getGeozonas().subscribe(data => {
      this.geozonasList = data;
      this.map.loadGeoZona(this.geozonasList)
      this.isLoading = false;
    })
  }

  getConfiguracionByIdGeozona(geozonaId: number) {
    this._geozonaService.getConfiguracionById(geozonaId).subscribe(data => {
      this.configuracionGeozona = data;
    });
  }

  getTipoEventoByIdGeozona(tipoEventoId: number) {
    this._geozonaService.getTiposEventos().subscribe(data => {
      const tipoEv = (data as TipoEvento[]).filter(ev => ev.idtipo_event_geo_id = tipoEventoId);
      this.tipoEventoGeozona = tipoEv[0];
    });
  }

  editGeozona(geozona: Geozona) {
    this.isLoading = true;
    this._geozonaService.getConfiguracionByGeozona(geozona.geozonas_id).subscribe(data => {
      geozona.configuracion = data;
      this._geozonaService.getTiposEventos().subscribe(ev => {
        const result = (ev as TipoEvento[]).filter(e => e.idtipo_event_geo_id = geozona.tipo_event_geo_id);
        geozona.tipo_evento = result[0];
        this.isLoading = false;
        geozona.action = 'editar'
        const dialogRef = this.dialog.open(FormGeozonaComponent, { data: geozona });
        dialogRef.afterClosed().subscribe(result => {

          if (result) {

            const geo = result as Geozona

            this.getGeozonas();
          }
        });
      })
    })
  }

  editDrawGeozona(geozona: Geozona) {

    for (const key in this.map.drawnItems._layers) {
      if (Object.prototype.hasOwnProperty.call(this.map.drawnItems._layers, key)) {
        const element = this.map.drawnItems._layers[key];

        for (const key in element._layers) {
          if (Object.prototype.hasOwnProperty.call(element._layers, key)) {
            const layer = element._layers[key];
            if (layer.feature.properties.geozonas_id == geozona.geozonas_id) {

              geozona.is_edit = true

              layer.enableEdit()
              this._snackBar.open('Edición Geozona', "Activada", {
                duration: 4000,
              });
            }
          }
        }
      }
    }
  }

  saveEditDrawGeozona(geozona: Geozona) {

    geozona.is_edit = false;
    for (const key in this.map.drawnItems._layers) {
      if (Object.prototype.hasOwnProperty.call(this.map.drawnItems._layers, key)) {
        const element = this.map.drawnItems._layers[key];

        for (const key in element._layers) {
          if (Object.prototype.hasOwnProperty.call(element._layers, key)) {
            const layer = element._layers[key];
            if (layer.feature.properties.geozonas_id == geozona.geozonas_id) {

              geozona.is_edit = true

              layer.on('editable:disable', event => {
                const layer = event.layer;
                const newGeometry = layer.toGeoJSON().geometry;
                geozona.figura = stringify(layer.toGeoJSON())

                this.isLoading = true
                this._geozonaService.editGeozona(geozona).subscribe( response => {

                  this.isLoading = false

                  this._snackBar.open('Geozona actualizada', "OK", {
                    duration: 4000,
                  });
                  this.getGeozonas();
                }, err => this.isLoading = false);
                

              });
              
              layer.disableEdit()
              console.dir(layer.feature.geometry.coordinates)
              console.dir(layer)

             
            }
          }
        }
      }
    }
 
    //this.getGeozonas();
  }

  cancelEditDrawGeozona(geozona: Geozona) {

    geozona.is_edit = false;
    for (const key in this.map.drawnItems._layers) {
      if (Object.prototype.hasOwnProperty.call(this.map.drawnItems._layers, key)) {
        const element = this.map.drawnItems._layers[key];

        for (const key in element._layers) {
          if (Object.prototype.hasOwnProperty.call(element._layers, key)) {
            const layer = element._layers[key];
            if (layer.feature.properties.geozonas_id == geozona.geozonas_id) {

              geozona.is_edit = true
              console.dir(layer.feature.properties)

              layer.disableEdit()
              this._snackBar.open('Edición Geozona', "Desactivada", {
                duration: 4000,
              });
            }
          }
        }
      }
    }
 
    this.getGeozonas();
  }

  deleteGeozona(geozona: Geozona) {

    const dialogRef = this.dialog.open(FormDeleteGeozonaComponent, {
      width: '450px',
      data: geozona
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        this.isLoading = true;
        this._geozonaService.deleteGeozonaById(geozona.geozonas_id).subscribe(
          res => {
            this.isLoading = false;
            this._snackBar.open('Eliminación exitosa', "Ok", {
              duration: 4000,
            });
            this.getGeozonas();
          },
          err => {
            this.isLoading = false;
            swal.fire('Error eliminar', 'Hay un problema al intentar eliminar la geozona', 'warning');
          }
        );
      }
    });

  }

  loadAll() {

    this.suscribir();
    this.loadGps();
  }

  suscribir() {

    this.tabla.emitEventCenterGps.subscribe(res => this.centerMap(res));
    this.tabla.emitSeeViewMap.subscribe(res => this.seeViewMap(res));
    this.tabla.emitSeeViewAllMap.subscribe(res => this.seeAllViewMap(res));
    this.tabla.emitOpenManu.subscribe(res => this.openMenuGps(res));
    this.map.emitRecargaGeozona.subscribe(res => {
      
      this.getGeozonas()
    });
  }

  startTaskUpdateGps() {

    setTimeout(() => {

      if (this.onRefresh) {

        this.loadUpdateGps(true);
      } else {
        this.startTaskUpdateGps();
      }
    }, 60000);

  }

  loadGps() {

    this.mapService.getAllLastPosition().subscribe(response => {

      this.gpsList = response as Array<GpsMap>;

      this.mapService.gpsList = this.gpsList;

      this.gpsList.forEach(g => {
        g.seeView = true;
      });
      this.tabla.addData(this.gpsList);
      this.insertGpsMap(this.gpsList);
      this.calTotal(this.gpsList);
      this.isLoadGps = false;

      if (this.gpsList.length > 0) {

        let gps = this.gpsList[0];
        this.map.centerMap(gps.latitud, gps.longitud);

        //Se desactiva la vista de los vahiculos en el mapa por defecto
        this.seeAllViewMap(false);
      }

      this.startTaskUpdateGps();

      this.openMsgBienvenida();
    }, (error) => {

      if (error.status == 203) {

        localStorage.clear();
        window.location.href = "login";
      }
      this.isRefresh = false;
    });


  }

  openMsgBienvenida() {

    var flagInicio = localStorage.getItem('flagInicio');

    if (flagInicio == undefined || flagInicio == null || flagInicio == "") {

      swal.fire({
        imageUrl: 'assets/img/banner002.jpg',
        imageHeight: 150,
        imageWidth: 600,
        title: '<strong>Te damos la bienvenida a una nueva experiencia de monitoreo</strong>',
        html: 'Diseñamos está nueva plataforma para ayudarte a detectar la ubicación más reciente del vehículo.' +
          '<table><tbody><tr><td>&nbsp; Puedes encontrar mas información en la opción "<b>Ayuda</b>", del menu superior o puedes enviar un mensaje a uno de nuestro ejecutivos en "<b>Atención al Cliente</b>" y se contactaran contigo. </td>' +
          '<td>&nbsp;<img height="200" src="https://www.teigps.cl/wp-content/uploads/2019/03/ManuOpciones.PNG" alt="" /></td></tr></tbody></table>',
        showCancelButton: false,
        backdrop: 'left center no-repeat rgba(0, 0, 0, 0.78)',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Continuar'
      }).then((result) => {
        localStorage.setItem('flagInicio', 'true');
        this.openMsgInicioCortaCombustible();
      })

    } else {
      this.openMsgInicioCortaCombustible();
    }
  }

  openMsgInicioCortaCombustible() {

    try {

      var contratos = JSON.parse(localStorage.getItem('contratos')) as Contrato[];

      if (!Array.isArray(contratos)) {
        var lista = new Array();
        lista.push(contratos as Contrato);
        contratos = lista;
      }

      if (contratos == null || contratos[0] == null) {

        return;
      }

      var cortaCorrienteNoPersonalizado = contratos.filter(c => c != null && c.cortaCorrientePersonal == 0);
      if (cortaCorrienteNoPersonalizado != null && cortaCorrienteNoPersonalizado.length > 0) {

        swal.fire({
          imageUrl: 'assets/img/nstalando_gps.jpg',
          imageHeight: 150,
          imageWidth: 600,
          title: '<strong>Debes activar la opción de corta corriente</strong>',
          /* html: 'Debes definir una contraseña para el uso del corta corriente. Para definir tu contraseña, te aconsejamos lo siguiente:<br><br>'+
          '<ur style="text-align: -webkit-left;font-size: 16px; ">'+
            '<li>Ingresa una contraseña distinta a la definida para acceder. </li>'+
            '<li>No compartir la contraseña, evita el uso indebido  y/o accidente. </li>'+
          '</ur>'+
          '<br><br> <b>NOTA</b>: En el caso que no quieras activar una contraseña para el uso del corta corriente, debes hacer click en "Sin contraseña"',*/
          html: 'Debes hacer click en "Activar" para que el corta corriente quede completamente habilitado. ',
          showCancelButton: true,
          showConfirmButton: false,
          backdrop: 'left center no-repeat rgba(0, 0, 0, 0.78)',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Definir contraseña',
          //cancelButtonText: 'Sin contraseña'
          cancelButtonColor: '#283593',
          cancelButtonText: 'Activar'
        }).then((result) => {
          //{dismiss: "backdrop"}
          if (result.value) {

            this.openSetPassCortaCorriente(cortaCorrienteNoPersonalizado[0].operacion);

          } else if (result['dismiss'].toString() == "cancel") {

            this.cortacorrienteService.getByOperacion(cortaCorrienteNoPersonalizado[0].operacion).subscribe(response => {

              var cortaCorrienteSeg = response as Cortacorrientepass;
              cortaCorrienteSeg.pass = '';
              cortaCorrienteSeg.isPass = "0";
              cortaCorrienteSeg.isPersonal = "1";

              this.cortacorrienteService.updatePass(cortaCorrienteSeg).subscribe(response => {

                swal.fire({
                  title: 'Listo!',
                  type: 'success',
                  backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
                  html: 'Ya se encuentra activa la opción para cortar la corriente de tu vehículo.',
                  confirmButtonText: 'Gracias!'
                });
                var contratos = JSON.parse(localStorage.getItem('contratos')) as Contrato[];
                contratos.forEach(c => c.cortaCorrientePersonal = 1);
                localStorage.setItem('contratos', JSON.stringify(contratos));
              });
            });
          } else {

            console.log(result);

          }
        })
      }

      contratos.filter(c => {

        return c.cortaCorrienteActivo == 1
      }).forEach(c => {

        this.contratosCortaCorriente.set(c.operacion, c);
      });
    } catch (error) {

      console.log(error);
      this.contratosCortaCorriente = new Map()
    }
  }

  openSetPassCortaCorriente(operacion: string) {

    swal.mixin({
      input: 'password',
      confirmButtonText: 'Siguiente',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      progressSteps: ['1', '2'],
      backdrop: 'left center no-repeat rgba(0, 0, 0, 0.78)'
    }).queue([
      {
        title: 'Contraseña para activar el corta corriente',
        text: 'Ingrese la contraseña'
      }, {
        title: 'Contraseña para activar el corta corriente',
        text: 'Repetir la nueva contraseña'
      }
    ]).then((result) => {
      if (result.value) {

        var passNew1: string = result.value[0];
        var passNew2: string = result.value[1];


        if (passNew1 == passNew2) {

          this.cortacorrienteService.getByOperacion(operacion).subscribe(response => {

            var cortaCorrienteSeg = response as Cortacorrientepass;
            cortaCorrienteSeg.pass = passNew1;
            cortaCorrienteSeg.isPass = "1";
            cortaCorrienteSeg.isPersonal = "1";


            this.cortacorrienteService.updatePass(cortaCorrienteSeg).subscribe(response => {

              swal.fire({
                title: 'Listo!',
                type: 'success',
                backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
                html: 'Las contraseñas fue actualizada. Te recomendamos que cierres tú cuenta e ingreses nuevamente para asegurar que el corta corriente fue activado.',
                confirmButtonText: 'Gracias!'
              });
              var contratos = JSON.parse(localStorage.getItem('contratos')) as Contrato[];
              contratos.forEach(c => c.cortaCorrientePersonal = 1);
              localStorage.setItem('contratos', JSON.stringify(contratos));
            });
          });

        } else {

          swal.fire({
            title: 'Las contraseñas ingresadas no coinciden.',
            type: 'error',
            backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
            html: '',
            confirmButtonText: 'Ok'
          });
        }


      }
    })
  }

  openPopUpCortaCorriente(gps: GpsMap, contrato: Contrato) {

  }

  emicionCortaCorrient(gps: GpsMap) {

  }

  loadUpdateGps(isStartTack: boolean) {

    this.isRefresh = true;

    var callback = (listaNueva: Array<GpsMap>) => {

      listaNueva.forEach(newValue => {

        this.gpsList.filter(g => newValue.serial == g.serial).forEach(g => {

          g.latitud = newValue.latitud;
          g.longitud = newValue.longitud;
          g.fechaEvento = newValue.fechaEvento;
          g.fechaGPS = newValue.fechaGPS;
          g.geo = newValue.geo;
          g.kms = newValue.kms;
          g.diff = newValue.diff;
          g.cortaCorrienteActivo = newValue.cortaCorrienteActivo;
          g.ignicion = newValue.ignicion;
          g.desconectado = newValue.desconectado;
          g.voltajeExterno = newValue.voltajeExterno;
          g.velocidadActual = newValue.velocidadActual;
          g.movimiento = newValue.movimiento;

          if (g.seeView) {
            //updateMap
            this.map.updateGps(g);
          }
        });

      });

      this.mapService.gpsList = this.gpsList;

      //this.filter();
      this.calTotal(this.gpsList);
      this.isRefresh = false;
      if (isStartTack) {

        this.startTaskUpdateGps();
      }

    }

    this.mapService.getAllLastPositionLiteByStatus('allactivados').subscribe(response => {

      var listaNueva = response as Array<GpsMap>;
      callback(listaNueva);
    }, (error) => {

      if (error.status == 203) {

        localStorage.clear();
        window.location.href = "login";
      }
      this.isRefresh = false;
    });

  }

  calTotal(list: Array<GpsMap>) {

    this.total = 0;
    this.totalActivate = 0;
    this.totalDesactivateMedio = 0;
    this.totalDesactivateFull = 0;

    this.total = list.length;

    this.totalActivate = list.map(gps => Number.parseInt(gps.diff.toString())).filter(diff => diff < 5).length;

    this.totalDesactivateMedio = list.map(gps => Number.parseInt(gps.diff.toString())).filter(diff => diff > 5 && diff < 24).length;

    this.totalDesactivateFull = list.map(gps => Number.parseInt(gps.diff.toString())).filter(diff => diff > 24).length;

  }

  callServiceByStatus(status, list: Array<GpsMap>) {

    this.mapService.getAllLastPositionByStatus(status).subscribe(response => {

      list = response as Array<GpsMap>;

    }, (error) => {

      if (error.status == 203) {

        localStorage.clear();
        window.location.href = "login";
      }
      this.isRefresh = false;
    });
  }

  insertGpsMap(data: Array<GpsMap>) {

    this.map.removeAllLayer();
    data.forEach(g => {
      this.map.addPonintMap(g)
    });

  }

  /**
   * Funcion que centra el mapa en base a una geozona.
   * @param geo
   */
  centerGeozonaMap(geo: Geozona) {

    this.map.centerGeozonnaMap(geo)
  }

  centerMap(gps: GpsMap) {

    this.map.centerMap(gps.latitud, gps.longitud);
  }

  seeViewMap(gps: GpsMap) {

    this.map.updateSeeViewGps(gps);
  }

  seeAllViewMap(isActivate: boolean) {

    this.gpsList.forEach(g => g.seeView = isActivate);
    if (isActivate) {

      this.filter();
      this.insertGpsMap(this.gpsList);
    } else {

      this.filter();
      this.insertGpsMap([]);
    }
  }

  filterByStatus(status) {

    if (status == 'activate') {

      let datos = this.gpsList.filter((gps) => gps.diff < 5);
      datos.forEach(g => {

        g.seeView = true;
        this.seeViewMap(g);
      });
      this.tabla.addData(datos);
      this.insertGpsMap(datos);
    } else if (status == 'desactivate_medio') {

      let datos = this.gpsList.filter((gps) => gps.diff > 4 && gps.diff < 24);
      datos.forEach(g => {

        g.seeView = true;
        this.seeViewMap(g);
      });
      this.tabla.addData(datos);
      this.insertGpsMap(datos);
    } else if (status == 'desactivate_full') {

      let datos = this.gpsList.filter((gps) => gps.diff > 23);
      datos.forEach(g => {

        g.seeView = true;
        this.seeViewMap(g);
      });
      this.tabla.addData(datos);
      this.insertGpsMap(datos);
    } else {

      this.tabla.hide = true;
      this.seeAllViewMap(true);
      this.tabla.addData(this.gpsList);
      this.insertGpsMap(this.gpsList);
    }
  }

  filter() {

    if (this.filterCar != undefined && this.filterCar != null && this.filterCar != "") {

      let datos = this.gpsList.filter((d) =>
        this.fixDataFilter(d.serial).toUpperCase().includes(this.filterCar.toUpperCase())
        || this.fixDataFilter(d.imei).toUpperCase().includes(this.filterCar.toUpperCase())
        || this.fixDataFilter(d.telefono).toUpperCase().includes(this.filterCar.toUpperCase())
        || this.fixDataFilter(d.operacion).toUpperCase().includes(this.filterCar.toUpperCase())
        || this.fixDataFilter(d.nombreModelo).toUpperCase().includes(this.filterCar.toUpperCase())
        || this.fixDataFilter(d.rutCliente).toUpperCase().includes(this.filterCar.toUpperCase())
        || this.fixDataFilter(d.vin).toUpperCase().includes(this.filterCar.toUpperCase())
      );

      datos.forEach(g => {

        g.seeView = true;
        this.seeViewMap(g);
      });

      this.tabla.addData(datos);
    } else {
      this.tabla.addData(this.gpsList);
    }
  }
  clearFilter() {

    this.filterCar = "";
    this.filter();
  }

  selectOption(data) {

    this.filterCar = data.serial;
    this.filter();
    this.centerMap(data);
  }

  filterAutoCompletado(val: string): GpsMap[] {

    this.searchText = val.toString();
    var searchValue = val.toString();
    if (searchValue == '') {

      searchValue = '0';
    }
    const valuesSearch = this.gpsList.filter(option => this.isValidateOption(option));

    if (valuesSearch.length > 15) {

      return valuesSearch.slice(0, 14);
    } else {

      return valuesSearch;
    }
  }

  public viewFormatSearhValue(optionImei: GpsMap) {

    if (optionImei.serial != null && optionImei.serial.toString().toLowerCase().includes(this.filterCar.toString().toLowerCase())) {

      return optionImei.serial.toString();
    } else if (optionImei.imei != null && optionImei.imei.toString().toLowerCase().includes(this.filterCar.toString().toLowerCase())) {

      return optionImei.imei.toString();
    } else if (optionImei.vin != null && optionImei.vin.toString().toLowerCase().includes(this.filterCar.toString().toLowerCase())) {

      return optionImei.vin.toString();
    } else if (optionImei.telefono != null && optionImei.telefono.toString().toLowerCase().includes(this.filterCar.toString().toLowerCase())) {

      return optionImei.telefono.toString();
    } else if (optionImei.rutCliente != null && this.validateIncludeRut(optionImei.rutCliente, this.filterCar)) {

      return optionImei.rutCliente.toString();
    } else {

      return optionImei.serial.toString() + ' - ' + optionImei.imei.toString() + ' - ' + optionImei.vin.toString() + ' - ' + optionImei.telefono.toString() + ' - ' + optionImei.operacion.toString() + ' - ' + optionImei.rutCliente.toString();
    }

  }

  public getInfoComun(optionImei: GpsMap) {

    return "Patente/Vin: " + optionImei.vin + " - Vehículo: " + optionImei.vehiculo + " - GPS:" + optionImei.serial + " - Operación:" + optionImei.operacion;
  }

  public isValidateOption(optionImei: GpsMap): boolean {

    if (optionImei.serial != null && optionImei.serial.toString().toLowerCase().includes(this.searchText.toString().toLowerCase())) {

      return true;
    } else if (optionImei.imei != null && optionImei.imei.toString().toLowerCase().includes(this.searchText.toString().toLowerCase())) {

      return true;
    } else if (optionImei.vin != null && optionImei.vin.toString().toLowerCase().includes(this.searchText.toString().toLowerCase())) {

      return true;
    } else if (optionImei.telefono != null && optionImei.telefono.toString().toLowerCase().includes(this.searchText.toString().toLowerCase())) {

      return true;
    } else if (optionImei.operacion != null && optionImei.operacion.toString().toLowerCase().includes(this.searchText.toString().toLowerCase())) {

      return true;
    } else if (optionImei.rutCliente != null && this.validateIncludeRut(optionImei.rutCliente, this.searchText)) {

      return true;
    } else {

      return false;
    }
  }

  public validateIncludeRut(rut, value) {

    if (rut == null || value == '') {

      return false;
    }
    var rutA = '';
    rutA = rut.replace('-', '');
    rutA = rutA.replace('.', '');
    rutA = rutA.replace('.', '');
    var rutB = '';
    rutB = value.replace('-', '');
    rutB = rutB.replace('.', '');
    rutB = rutB.replace('.', '');
    return rutA.toString().includes(rutB.toString());
  }

  fixDataFilter(valor) {

    if (valor == null) {
      return "";
    }
    return valor;
  }

  openMenuGps(gps: GpsMap): void {
    
    this.mapService.getAllLastPosition().subscribe(data=>{
      localStorage.setItem("gpsSelector", JSON.stringify(data[0])); 
    })
    this.bottomSheet.open(BottomSheetMenu);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {

    this.onRefresh = true;
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {

    this.onRefresh = false;
  }
}

@Component({
  selector: 'bottom-sheet-menu',
  templateUrl: './bottom-sheet-menu.html',
})
export class BottomSheetMenu {

  gps: GpsMap;
  contratosCortaCorriente: Map<string, Contrato> = new Map();

  contratosOdometro: Map<string, Contrato> = new Map();
  contratoCrashEvent: Map<string, Contrato> = new Map();

  visibleCortaCorrienteOn = true;
  visibleOdometroOn = true;
  textoOpcionCrash: String;

  constructor(private bottomSheetRef: MatBottomSheetRef<BottomSheetMenu>,
    public mapService: MapService,
    public cortacorrienteService: CortacorrienteService,
    public cortacorrienteLogService: CortacorrientelogService,
    public imeiService: ImeiService,
    private dialog: MatDialog) {

    this.gps = JSON.parse(localStorage.getItem("gpsSelector")) as GpsMap;

    if (this.gps.modeloGpsId == "3" || this.gps.modeloGpsId == "4" || this.gps.modeloGpsId == "5" || this.gps.modeloGpsId == "6") {

      this.textoOpcionCrash = "Detección de choques - Solicita la activación ahora";
    } else {
      this.textoOpcionCrash = "Detección de choques no contratado";
    }

    //Desactivación visibilidad del corta corriente
    if (this.gps.modeloGpsId == "4" || this.gps.modeloGpsId == "5" || this.gps.modeloGpsId == "7") {

      this.visibleCortaCorrienteOn = false;
    }

    var contratos = JSON.parse(localStorage.getItem('contratos')) as Contrato[];
    if (!Array.isArray(contratos)) {
      var lista = new Array();
      lista.push(contratos as Contrato);
      contratos = lista;
    }

    if (contratos != null && contratos[0] != null) {

      contratos.filter(c => c.cortaCorrienteActivo == 1).forEach(c => this.contratosCortaCorriente.set(c.operacion, c));
      contratos.filter(c => c.crashActivo == 1).forEach(c => this.contratoCrashEvent.set(c.operacion, c));
      contratos.filter(c => c.odometroActivo == 1).forEach(c => this.contratosOdometro.set(c.operacion, c));

    
    }
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  openLinkExternal(link) {

    var a = document.createElement("a");
    a.href = link;
    a.target = '_blank';
    a.click();
  }

  openLinkWaze(gps: GpsMap) {

    var url = "https://embed.waze.com/es/iframe?zoom=12&lat=" + gps.latitud + "&lon=" + gps.longitud + "&pin=1";
    var a = document.createElement("a");
    a.href = url;
    a.target = '_blank';
    a.click();
    window.open(url);
  }

  openControlVelocidad(gps: GpsMap) {

    var requestLimiteVelocida = { id: Number.parseInt(gps.imeiId), limiteVelocidad: gps.limiteVelocidad } as LimiteVelocidadRequest;

    const dialogRef = this.dialog.open(DialogControlVelocidad, {
      data: requestLimiteVelocida
    });

    dialogRef.afterClosed().subscribe(result => {

      var r = result as LimiteVelocidadRequest;

      if (result == undefined || r.limiteVelocidad == undefined) {

        return;
      }
      console.log(`Limite: ${r.limiteVelocidad}`);
      gps.limiteVelocidad = r.limiteVelocidad;

      this.mapService.gpsList.forEach(g => {
        if (g.imeiId == gps.imeiId) {

          g.limiteVelocidad = gps.limiteVelocidad;
        }
      });

    });
  }

  openHistorialConductores(gps: GpsMap) {

    const dialogRef = this.dialog.open(PantallaReporteConductorVehiculoComponent, {
      data: gps,
      width: '650px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }


  openLinkGoogleMap(gps: GpsMap) {

    var url = "https://maps.google.com/?q=" + gps.latitud + "," + gps.longitud + "&om=1&speed:0";
    var a = document.createElement("a");
    a.href = url;
    a.target = '_blank';
    a.click();
    window.open(url);
  }

  openLinkStreetView(gps: GpsMap) {

    var url = "https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=" + gps.latitud + "," + gps.longitud + "&heading=-45&pitch=38&fov=80";
    var a = document.createElement("a");
    a.href = url;
    a.target = '_blank';
    a.click();
    window.open(url);
  }

  openPopUpCortaCorriente(gps: GpsMap, contrato: Contrato) {

    var mensajeLastCortacorriente = '';
    var onRedyBtn = true;

    this.cortacorrienteService.getLastCortacorriente(gps.imeiId).subscribe( response => {

      const last_cortacorriente = response['last_cortacorriente'] as Array<Configuracion>;
      if (last_cortacorriente &&  last_cortacorriente.length > 0){

        if (last_cortacorriente[0].valor != '0' && last_cortacorriente[0].estado_medio_envio == '0' ) {

          mensajeLastCortacorriente = "El envío del corta corriente está en proceso. Por favor, espere un momento y revise nuevamente";
          onRedyBtn = false;
        } else if (last_cortacorriente[0].valor != '0' && last_cortacorriente[0].estado_medio_envio == '1' )  {
          mensajeLastCortacorriente = "El último corta corriente fue enviado correctamente a su vehículo"
        } else if (last_cortacorriente[0].valor != '0' && last_cortacorriente[0].estado_medio_envio == '-1' ) {
          mensajeLastCortacorriente = "El último envío del corta corriente no se aplicó. Intente nuevamente o espere un momento antes de reintentar"
        }  else if (last_cortacorriente[0].valor == '0' && last_cortacorriente[0].estado_medio_envio == '1' )  {
          mensajeLastCortacorriente = "La última activación de corriente fue enviado correctamente a su vehículo"
        } else if (last_cortacorriente[0].valor == '0' && last_cortacorriente[0].estado_medio_envio == '-1' ) {
          mensajeLastCortacorriente = "El último envío de la activación de corriente no se aplicó. Intente nuevamente o espere un momento antes de reintentar"
        }  else if (last_cortacorriente[0].valor == '0' && last_cortacorriente[0].estado_medio_envio == '0'  ) {
          mensajeLastCortacorriente = "El envío de la activación de corriente está en proceso. Por favor, espere un momento y revise nuevamente";
          onRedyBtn = false;
        }

        const isCortaCorrienteActivo = ((last_cortacorriente[0].valor != '0' && last_cortacorriente[0].estado_medio_envio == '1')
        || (last_cortacorriente[0].valor == '0' && last_cortacorriente[0].estado_medio_envio == '-1'))
        runSwal(isCortaCorrienteActivo);
      } else {
        runSwal()
      }

      
    }, error => {

      console.error("Error al consultar last cortacorriente", error);
      runSwal();
    })

    const runSwal = (isCortaCorrienteActivo: boolean = false) => {

      swal.fire({
        imageUrl: 'assets/img/corta_corriente.png',
        imageHeight: 293,
        imageWidth: 341,
        title: '<strong>Corta corriente</strong>',
        html: ((gps.cortaCorrienteActivo == false || isCortaCorrienteActivo) ? 'Haga click en <b>"Cortar corriente"</b> si quiere que el dispositivo corte la corriente en el vehículo. ' : 'Haga click en <b>"Activar corriente"</b> para que se active la corriente en su vehículo.' + ' <br><br>' +
          '<blockquote>' +
          '<b>Nota: </b> El corte de corriente solo se aplicara cuando el vehículo se encuentre detenido.' +
          '</blockquote>') + 
          (mensajeLastCortacorriente ? '<blockquote style="size: 11px;color: cadetblue;">' +
          '<b>¡'+ mensajeLastCortacorriente  +'!</b>' +
          '</blockquote>' : ''),
        showConfirmButton: onRedyBtn,
        showCancelButton: true, // gps.cortaCorrienteActivo,
        backdrop: 'left center no-repeat rgba(0, 0, 0, 0.78)',
        confirmButtonColor: ( isCortaCorrienteActivo == false) ? '#CD5C5C' : '#3085d6',
        //cancelButtonColor: '#CD5C5C',
        confirmButtonText: (isCortaCorrienteActivo == false) ? 'Cortar corriente' : 'Activar corriente',
        cancelButtonText: 'Cancelar'
      }).then((result) => {


        if (result.value) {

          var g: GpsMap = Object.assign({}, gps) as GpsMap;
          if (gps.cortaCorrienteActivo == false) {
            //Cortar la corriente
            g.cortaCorrienteActivo = true;
          } else {
            //Activar la corriente
            g.cortaCorrienteActivo = false;
          }
          this.cortacorrienteService.getByRutIsPass(g.rutCliente).subscribe(response => {
            var cortaPass = response as Cortacorrientepass;

            var paqueteFlow = {} as Cortacorriente;
            paqueteFlow.rut = cortaPass.rut;
            paqueteFlow.pass = '';
            paqueteFlow.operacion = g.operacion;
            paqueteFlow.isActivo = (isCortaCorrienteActivo == false) ? 0 : 1;
            paqueteFlow.id_gps = Number.parseInt(gps.imeiId);

            if (cortaPass.isPass == '1') {

              // solicitar pass
              swal.fire({
                title: 'Ingrese su contraseña de corta corriente',
                input: 'password',
                inputAttributes: {
                  autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                showLoaderOnConfirm: true,
              }).then((pass) => {

                if (pass.value == cortaPass.pass) {

                  //enviar accion
                  this.cortaCorrienteFlow(paqueteFlow);
                } else {


                  swal.fire({
                    title: 'Contraseña Incorrecta',
                    type: 'error',
                    backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
                    html: 'La contraseña ingresada no es valida, vuelve a intentar!<p>Si tienes dudas contactese con servicio al cliente</p>',
                    confirmButtonText: 'Ok'
                  }).then(v => this.openPopUpCortaCorriente(gps, contrato));
                  console.log("error");
                  //.then(v => this.openPopUpCortaCorriente(gps, contrato));
                }
              });
            } else {

              //enviar accion
              this.cortaCorrienteFlow(paqueteFlow);
            }
          });
        }
        // else -> no hacer nada 
      })
    } 

  }

  cortaCorrienteFlow(cortaSeguridad: Cortacorriente) {

    swal.fire({
      title: 'Selecciona un motivo',
      input: 'select',
      inputAttributes: {
        autocapitalize: 'off'
      },
      inputOptions: { PREVENIR_ROBO: 'Prevenir robo', ROBO_VEHICULO: 'Robo de vehículo', ASUNTO_PERSONAL: 'Asunto personal' },
      showCancelButton: false,
      confirmButtonText: 'Aceptar',
      showLoaderOnConfirm: true,
    }).then((result) => {

      cortaSeguridad.msg = result.value;

      this.cortacorrienteService.flowCortaCorriente(cortaSeguridad).subscribe(response => {

        swal.fire({
          title: (cortaSeguridad.isActivo == 0) ? 'Se ha enviado una solicitud de corte de corriente' : 'Se ha enviado una solicitud de activación de corriente',
          type: 'success',
          backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
          html: 'Debe esperar aproximadamente 1 minuto para que se realice el cambio!',
          confirmButtonText: 'Aceptar'
        });

        this.gps.cortaCorrienteActivo = (cortaSeguridad.isActivo == 0) ? true : false;
      });

      var log = {} as Cortacorrientelog;

      log.esCortaCorriente = cortaSeguridad.isActivo == 0 ? 1 : 0;
      log.fechaEvento = (new Date()).toLocaleString();
      log.log = JSON.stringify(cortaSeguridad);
      log.operacion = cortaSeguridad.operacion;
      log.rutCliente = cortaSeguridad.rut;
      log.serialGps = cortaSeguridad.id_gps.toString();
      this.cortacorrienteLogService.create(log).subscribe(r => console.log("registro de corta corriente"));
    });
  }

  openCortaCorrienteNoContratado() {

    swal.fire({
      imageUrl: 'assets/img/corta_corriente.png',
      imageHeight: 293,
      imageWidth: 341,
      title: '<strong>¿Quieres contratar el corta corriente?</strong>',
      html: ' Llama al <b><a href="tel:6003600181">600 3600 181</a></b> y un ejecutivo te entregara todos los detalles, o también si así lo deseas, nos puedes escribirnos desde la opción <b>"Atención al Cliente"</b>.<br><br>',
      showConfirmButton: false,
      showCancelButton: true,
      backdrop: 'left center no-repeat rgba(0, 0, 0, 0.78)',
      cancelButtonColor: '#283593',
      cancelButtonText: 'Cerrar'
    }).then((result) => { });
  }

  openReporteDeteccionChoque(gps: GpsMap, contrato: Contrato) {

    swal.fire({
      imageUrl: 'assets/img/contratar-sos.png',
      imageHeight: 150,
      imageWidth: 600,
      title: '<strong>¿Quieres contratar la detección de choques?</strong>',
      html: ' Llama al <b><a href="tel:6003600181">600 3600 181</a></b> y un ejecutivo te entregara todos los detalles, o también si así lo deseas, nos puedes escribirnos desde la opción <b>"Atención al Cliente"</b>'
        + '<p>Esta funcionalidad detecta el accidente por medio de sensores internos del equipo GPS, y de manera inmediata envía una notificación de emergencia vía SMS con tu ubicación a los contactos, registrados en el sistema previamente. Esto puede llegar a reducir el tiempo en que los servicios de urgencia son notificados del accidente, gracias a uno de tus contactos.</p> <br><br>',
      showConfirmButton: false,
      showCancelButton: true,
      backdrop: 'left center no-repeat rgba(0, 0, 0, 0.78)',
      cancelButtonColor: '#283593',
      cancelButtonText: 'Cerrar'
    }).then((result) => { });
  }

  openDeteccionChoqueNoContratado() {

    swal.fire({
      imageUrl: 'https://www.teigps.cl/wp-content/uploads/2018/05/instalando_gps.jpg',
      imageHeight: 150,
      imageWidth: 600,
      title: '<strong>¿Quieres contratar el corta corriente?</strong>',
      html: ' Llama al <b><a href="tel:6003600181">600 3600 181</a></b> y un ejecutivo te entregara todos los detalles, o también si así lo deseas, nos puedes escribirnos desde la opción <b>"Atención al Cliente"</b>.<br><br>',
      showConfirmButton: false,
      showCancelButton: true,
      backdrop: 'left center no-repeat rgba(0, 0, 0, 0.78)',
      cancelButtonColor: '#283593',
      cancelButtonText: 'Cerrar'
    }).then((result) => { });
  }

  openCambioAlias(gps: GpsMap){
    const dialogRef = this.dialog.open(DialogCambiarAlias, {
      data: gps
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        for (let index = 0; index < this.mapService.gpsList.length; index++) {
          if(this.mapService.gpsList[index].operacion == result.operacion){
            this.mapService.gpsList[index].alias = result.alias
          }
        }
      }
    });
  }

  openAsignacionConductor(gps: GpsMap){
    const dialogRef = this.dialog.open(DialogCambiarConductor, {
      data: {GpsMap: gps, Conductor: null},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        for (let index = 0; index < this.mapService.gpsList.length; index++) {
          if(this.mapService.gpsList[index].operacion == result.operacion){
            if(result.conductores_id == 0){
              this.mapService.gpsList[index].conductores_id = null;
              this.mapService.gpsList[index].conductor_nombre_completo = null;
            }else{
              this.mapService.gpsList[index].conductores_id = result.conductores_id;
              this.mapService.gpsList[index].conductor_nombre_completo = result.nombre_completo;
            }
            
          }
        }
      }
    });
  }
}


@Component({
  selector: 'dialog-control-velocidad',
  templateUrl: 'dialog-control-velocidad.html',
  styleUrls: ['dialog-control-velocidad.css']
})
export class DialogControlVelocidad {

  //limiteVelocidadRequest: LimiteVelocidadRequest;

  notificacion_activa_bool = false;
  close = false;

  constructor(private imeiService: ImeiService, public dialogRef: MatDialogRef<DialogControlVelocidad>, @Inject(MAT_DIALOG_DATA) public limiteVelocidadRequest: LimiteVelocidadRequest) {

  }


  cerrarSave() {

    this.imeiService.updateLimiteVelocidad(this.limiteVelocidadRequest).subscribe(r => {

      console.log(r);
    });

    this.close = true;
    this.dialogRef.close(this.limiteVelocidadRequest);
  }

  isError() {

    var _error = false;
    var msg_error = "";

    if ((this.limiteVelocidadRequest.limiteVelocidad == undefined || this.limiteVelocidadRequest.limiteVelocidad == null) || isNaN(this.limiteVelocidadRequest.limiteVelocidad) || this.limiteVelocidadRequest.limiteVelocidad < 10) {

      msg_error = msg_error + " La velocidad ingresada debe ser mayor o igual a 10 Km/K. Ejemplo: 80."
      _error = true;
    }

    return msg_error;
  }


}

@Component({
  selector: 'dialog-cambiar-alias',
  templateUrl: 'dialog-cambiar-alias.html',
  styleUrls: ['dialog-cambiar-alias.css']
})
export class DialogCambiarAlias {

  close = false;
  alias_textbox = ""
  public alias: Alias;
  _error = false;
  msg_error = "";
  constructor(public aliasService: AliasService, public dialogRef: MatDialogRef<DialogCambiarAlias>, @Inject(MAT_DIALOG_DATA) public gps: GpsMap) {
    this.alias = {} as Alias;
  }

  cerrarSave() {

    if (this.alias_textbox.trim() == ''){
        this.msg_error = " El alias no puede estar vacío."
        this._error = true;
        return;
    }else if(this.alias_textbox.length > 20){
        this.msg_error = " El alias no puede ser mayor a 20 caracteres."
        this._error = true;
        return;
    }
    else{
      this.alias.operacion = this.gps.operacion
      this.alias.alias = this.alias_textbox.toUpperCase();
      
      if(this.gps.alias != null){
        this.aliasService.update(this.alias).subscribe(result => {

          swal.fire({
            title: 'Se ha actualizado el alias',
            type: 'success',
            backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
            html: 'Se ha actualizado el alias para el vehículo.',
            confirmButtonText: 'Ok'
          }).then((result) => {
            this._error = false;
            this.dialogRef.close(this.alias);
            return;
          });
        })
      }else{
        this.aliasService.create(this.alias).subscribe(result => {

          swal.fire({
            title: 'Se ha creado el alias',
            type: 'success',
            backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
            html: 'Se ha creado el alias para el vehículo.',
            confirmButtonText: 'Ok'
          }).then((result) => { 
            this._error = false;
            this.dialogRef.close(this.alias);
            return;
          });
        })
      }
      
    } 

  }
}

@Component({
  selector: 'dialog-cambiar-conductor',
  templateUrl: 'dialog-cambiar-conductor.html',
  styleUrls: ['dialog-cambiar-conductor.css']
})
export class DialogCambiarConductor {

  close = false;
  public loading: boolean = true;
  
  public conductor: Conductor;
  public observacion: string;
  public vehiculo: GpsMap;
  public fecha_asignacion: Date;
  public user: User;

  public esEdicionVehiculo: boolean;
  public esEdicionConductor: boolean;

  public listaConductores: Array<Conductor>;
  public listaConductoresBackend: Array<Conductor>
  public gps: GpsMap;
  public listaVehiculos: Array<GpsMap>

  _error = false;
  msg_error = "";
  

  constructor(public mapService: MapService,public historialAsignacionService: HistorialAsignacionConductorService, public conductorService: ConductorService, public dialogRef: MatDialogRef<DialogCambiarConductor>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.user = JSON.parse(localStorage.getItem('user')) as User;
    this.esEdicionVehiculo = true;
    this.esEdicionConductor = true;
    this.conductorService.getAll().subscribe(result => {
      this.listaConductoresBackend = result as Array<Conductor>;
      this.listaConductores = this.listaConductoresBackend.filter(f => f.activo == 1);
      
      if(this.listaConductores.length == 0){
        swal.fire({
          title: 'No se han encontrado conductores',
          type: 'warning',
          backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
          html: 'Valide que existan y se encuentren activos en la pantalla de Administración de conductores.',
          confirmButtonText: 'Continuar'
        });
        this.dialogRef.close(false);
      }

      if(this.data.Conductor != null){
        this.esEdicionConductor = false;
        this.conductor = this.data.Conductor as Conductor;
        if(this.listaConductores.filter(f => (f.conductores_id == this.conductor.conductores_id))[0] != null){
            this.conductor = this.listaConductores.filter(f => (f.conductores_id == this.conductor.conductores_id))[0]
        }else{
          swal.fire({
            title: 'Conductor desactivado',
            type: 'warning',
            backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
            html: 'El conductor está desactivado. Actívelo desde la página de Administración de conductores',
            confirmButtonText: 'Aceptar'
            
          });
          this.dialogRef.close(false);
        }
      }

      if(this.data.GpsMap != null){
        this.esEdicionVehiculo = false;
        this.gps = this.data.GpsMap as GpsMap;
        if(this.listaConductores.filter(f => (f.op_vehiculo == this.gps.operacion))[0] != null){
            this.conductor = this.listaConductores.filter(f => (f.op_vehiculo == this.gps.operacion))[0]
        }
      }
      
      this.mapService.getAllLastPositionFromDB().subscribe(result => {
        this.listaVehiculos = result as Array<GpsMap>;
  
        if(this.data.Conductor != null){
          this.esEdicionConductor = false;
          if(this.listaVehiculos.filter(f => (f.conductores_id == this.conductor.conductores_id))[0] != null){
              this.vehiculo = this.listaVehiculos.filter(f => (f.conductores_id == this.conductor.conductores_id))[0]
              
          }
          this.loading = false;
        }
  
        if(this.data.GpsMap != null){
          this.esEdicionVehiculo = false;
          if(this.listaVehiculos.filter(f => (f.operacion == this.gps.operacion))[0] != null){
              this.vehiculo = this.listaVehiculos.filter(f => (f.operacion == this.gps.operacion))[0]
              
            }
          this.loading = false;
        }
      })
    })
  }

  asociarVehiculo() {

    this.msg_error = "";
    this._error = false;

    if (this.conductor == undefined || this.conductor == null){
      this.msg_error = " Debe seleccionar un conductor."
      this._error = true;
    }else{
      if(this.conductor.activo == 0){
        this.msg_error = " El conductor está desactivado. Actívelo desde la página de administración de conductores."
        this._error = true;
      }
    }
    if(this.fecha_asignacion == undefined || this.fecha_asignacion == null ){
      this.msg_error = this.msg_error + " La fecha de asignación es obligatoria."
      this._error = true;
    }
    if (this.vehiculo == undefined || this.vehiculo == null){
      this.msg_error = this.msg_error + " Debe seleccionar un vehiculo."
      this._error = true;
    }
    
    if(!this._error){

      let asignacionVehiculo = {} as HistorialAsignacionConductor;
      asignacionVehiculo.conductores_id = this.conductor.conductores_id;
      asignacionVehiculo.op_vehiculo = this.vehiculo.operacion;
      asignacionVehiculo.cliente_key = this.user.rut;
      asignacionVehiculo.fecha_asignacion = this.fecha_asignacion.toISOString().split("T")[0];
     
      swal.fire({
        title: "Asociar conductor",
        text: "Va a asociar el conductor. Puede dejar un comentario o presionar asignar",
        input: 'text',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Asignar',
        cancelButtonText: 'Cancelar',
        inputValidator: (value) => {
          if(value.length > 200){
            return 'La observación no puede superar los doscientos caracteres.'
          } 
        }        
      }).then((result) => {
        if (result.value.length > 0) {
          asignacionVehiculo.observacion_inicio = result.value.toString().toUpperCase();
        }
        this.conductorService.asociarVehiculo(asignacionVehiculo).subscribe(result =>{
  
          swal.fire({
            title: 'Listo!',
            type: 'success',
            backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
            html: 'La asignación ha sido realizada con éxito',
            confirmButtonText: 'Continuar'
          });
          this._error = false;
          if(this.esEdicionVehiculo){
            let actualizarFormulario = {} as any;
            actualizarFormulario.conductores_id = asignacionVehiculo.conductores_id
            actualizarFormulario.conductor_id = asignacionVehiculo.conductores_id
            actualizarFormulario.op_vehiculo = this.vehiculo.operacion
            actualizarFormulario.nombre_completo = this.conductor.nombre_completo
            actualizarFormulario.vin = this.vehiculo.vin
            actualizarFormulario.operacion = this.vehiculo.operacion
            this.dialogRef.close(actualizarFormulario);
          }
          if(this.esEdicionConductor){
            let actualizarFormulario = {} as any;
            actualizarFormulario.conductores_id = this.conductor.conductores_id
            actualizarFormulario.nombre_completo = this.conductor.nombre_completo
            actualizarFormulario.operacion = this.vehiculo.operacion
            this.dialogRef.close(actualizarFormulario);
            return;
          }
          
        });
      })
    }
  }

  desasociarVehiculo(){

    let asignacionVehiculo = {} as HistorialAsignacionConductor;
    asignacionVehiculo.cliente_key = this.user.rut;
    if(!this.esEdicionVehiculo){
      asignacionVehiculo.conductores_id = this.vehiculo.conductores_id;
    }
    if(!this.esEdicionConductor){
      asignacionVehiculo.conductores_id = this.conductor.conductores_id;
    }

    swal.fire({
      title: "Desasociar conductor",
      text: "Va a desasociar el conductor. Puede dejar un comentario o presionar desasignar",
      input: 'text',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Desasignar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if(value.length > 200){
          return 'La observación no puede superar los doscientos caracteres.'
        } 
      }        
    }).then((result) => {
      if (result.value.length > 0) {
        asignacionVehiculo.observacion_fin = result.value.toString().toUpperCase();
      }
      this.conductorService.desasociarVehiculo(asignacionVehiculo).subscribe(result =>{
        swal.fire({
          title: 'Listo!',
          type: 'success',
          backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
          html: 'La desasignación ha sido realizada con éxito',
          confirmButtonText: 'Continuar'
        });
        this._error = false;
        if(this.esEdicionVehiculo){
          let actualizarFormulario = {} as any;
          actualizarFormulario.conductor_id = asignacionVehiculo.conductores_id
          actualizarFormulario.conductores_id = 0
          actualizarFormulario.vin = null
          actualizarFormulario.op_vehiculo = 0
          actualizarFormulario.nombre_completo = this.conductor.nombre_completo
          actualizarFormulario.operacion = this.conductor.op_vehiculo
          this.dialogRef.close(actualizarFormulario);
        }
        if(this.esEdicionConductor){
          let actualizarFormulario = {} as any;
          actualizarFormulario.conductores_id = 0
          actualizarFormulario.nombre_completo = ''
          actualizarFormulario.operacion = this.vehiculo.operacion
          this.dialogRef.close(actualizarFormulario);
        }
        return;
      });
    });
  }

}
